import { render, staticRenderFns } from "./goodsPurchaseclassify.vue?vue&type=template&id=854071ba&scoped=true"
import script from "./goodsPurchaseclassify.vue?vue&type=script&lang=js"
export * from "./goodsPurchaseclassify.vue?vue&type=script&lang=js"
import style0 from "./goodsPurchaseclassify.vue?vue&type=style&index=0&id=854071ba&prod&lang=scss&scoped=true"
import style1 from "./goodsPurchaseclassify.vue?vue&type=style&index=1&id=854071ba&prod&lang=scss&scoped=true"
import style2 from "./goodsPurchaseclassify.vue?vue&type=style&index=2&id=854071ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "854071ba",
  null
  
)

export default component.exports